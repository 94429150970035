* {
  background: whitesmoke;
}
.App {
  display: block;
  padding-top: 25px;
  margin: auto;
  width: 90%;
  font-family: Arial, Helvetica, sans-serif;
}
header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  padding-top: 5px;
  border-radius: 12px;
}
nav > ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -44px;
  margin-top: -30px;
  background: black;
  border-left: dotted 2px;
  border-right: dotted;
  width: 230px;
}
nav > ul > li {
  margin: 5px;
  background: black;
  align-self: center;
  list-style: none;
  margin-left: -30px;
  margin-right: 8px;
}
.nav-link {
  background: black;
  color: white;
  text-shadow: gray 3px 3px;
  font-weight: bold;
  font-size: 18px;
  align-self: center;
  list-style: none;
}
h1 {
  text-align: center;
  background: black;
  color: white;
  margin-top:-3px;
  text-shadow: gray 3px 3px;
  width: 270px;
  border-left: dotted 2px;
  border-right: dotted 2px;
  padding-bottom: 4px;
  transform: rotate(357deg);
  font-size: 26px;
  margin-left: -44px;
}
h2 {
  text-align: center;
  color: slategrey;
  font-style: italic;
  line-height: 40px;
  text-decoration: underline overline;
  margin: 10px;
  margin-top:-10px;
}
#button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}
#forum-container {
  border: double 1.5px white;
  border-radius: 10px;
  padding: 3px;
  margin-right: 30px;
}
h5 {
  line-height: 8px;
  font-size: 10px;
  margin-left: -2px;
}
.comment-content {
  background: white;
}

ul {
  list-style-type: none;
}
select {
  height: 26px;
  width: 126px;
  margin-left: 10px;
  border-radius: 4px;
  background: white;
}
input {
  height: 26px;
  font-size: 18px;
  margin-bottom: 10px;
}
#search-bar-input {
  width: 225px;
  font-size: smaller;
}
label,
select {
  font-size: 15px;
}
label {
  display: inline-block;
  width: 100px;
  text-align: right;
  padding-right: 10px;
}
input,
textarea {
  border-radius: 4px;
  width: 154px;
  background: white;
}
textarea {
  height: 150px;
}
input:focus,
textarea:focus {
  background: lightsteelblue;
}
button {
  border-radius: 4px;
  background: black;
  color: white;
  height: 24px;
  margin-right: 3px;
  font-size: 17px;
}
#post-button {
  background: black;
  color: white;
}
#update-cancel {
  background: black;
  color: white;
}
.delete-button {
  height: 21px;
  width: 21px;
  padding-bottom: 5px;
  font-size: 8px;
  line-height: 14px;
  font-weight: bold;
  background: black;
  color: white;
}
a {
  text-decoration: none;
  color: black;
  font-size: 17px;
  background: lightgray;
}
.post-box {
  border: 1.2px solid black;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 3px;
  margin-left: -40px;
}
.post-content {
  line-height: 28px;
}
.title-container {
  display: flex;
  flex-direction: row;
}
.fa-container {
  display: flex;
  flex-direction: row;
  background: whitesmoke;
}
a > .fa {
  margin-left: 12px;
  font-size: 25px;
  padding-top: 15px;
}
.comment-box {
  border: 1.2px solid lightslategray;
  background: white;
  border-radius: 4px;
  padding-top: -20px;
  padding: 0px 15px 15px 15px;
  margin-top: 3px;
  margin-left: -40px;
}
.submit-button {
  margin-left: 3px;
  background: black;
  color: white;
}
.submit-post {
  margin-left: 60px;
}
.comment-header {
  background: white;
}
.required{ 
  color: red;
}
#signup-form-container, #login-container {
  width: 320px;
  margin: auto;
}
@media only screen and (min-width: 460px) {
  #signup-form-container, #login-container {
    width: 460px;
  }
  label, select {
    font-size: 20px;
  }
  label {width: 200px;}
  input, textarea {
    width: 180px;
  }
  h1 {
    width: 450px;
    font-size: 45px;
    margin-left: 0px;
  }
  h5 {
    font-size: 12px;
  }
  #search-bar-input {
    width: 350px;
    font-size: medium;
  }
  textarea {
    border-radius: 4px;
    width: 400px;
    background: white;
  }
  nav > ul {
    margin-left:0px;
  }
}
@media only screen and (min-width: 820px) {
  h1 {
    margin-top: -80px;
  }
  textarea {
    border-radius: 4px;
    width: 500px;
    background: white;
  }
  .right {
    text-align: right;
  }
  nav {
    align-self: flex-end;
  }
  nav > ul {
    display: flex;
    flex-direction: column;
    background: black;
    border-top: dotted 2px;
    border-bottom: dotted;
    text-align: center;
    list-style: none;
    width: 50px;
  }
  nav > ul > li {
    margin: 5px;
    background: black;
    align-self: center;
    list-style: none;
    margin-left: -30px;
    margin-right: 8px;
  }
  .nav-link {
    background: black;
    color: white;
    text-shadow: gray 3px 3px;
    font-weight: bold;
    font-size: 24px;
    align-self: center;
    list-style: none;
  }
}
@media only screen and (min-width: 1020px) {
  h1 {
    margin-top: -80px;
    text-align: center;
    background: black;
    color: white;
    text-shadow: gray 3px 3px;
    width: 650px;
    border-left: dotted 2px;
    border-right: dotted 2px;
    padding-bottom: 4px;
    transform: rotate(357deg);
    font-size: 55px;
  }
  input {
    height: 26px;
    font-size: 18px;
    margin-bottom: 10px;
  }
}
